import {
  Container,
  Heading1,
  Icon,
  PageLink,
  Paragraph,
} from "shared/components";

import styles from "./Hero001.module.scss";

export const Hero001 = ({ payload }) => {
  const headline = payload?.headline ?? "";
  const description = payload?.description ?? "";
  const linkText = payload?.linkText ?? "";
  const link = payload?.page ?? {};

  return (
    <div className={styles.wrapper}>
      <Container>
        <div className={styles.content}>
          <Heading1 className={styles.title} color="dark">
            {headline}
          </Heading1>
          <Paragraph className={styles.description} color="dark">
            {description}
          </Paragraph>
          <div className={styles["top-circle"]}>
            <div className={styles["inside-circle"]}>
              <PageLink slug={link.slug} className={styles.link}>
                <span className={styles.arrow}>
                  <Icon className={styles.icon} name="large_arrow_right" />
                </span>
                <Paragraph color="brand" appearance="bigger">
                  {linkText}
                </Paragraph>
              </PageLink>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero001;
